;(function () {
  const digitsRE = /(\d{3})(?=\d)/g

  window.Vue.use(window.Vuex)

  window.Vue.filter('currency', function (value) {
    var prevalue = parseFloat(value)
    if (!isFinite(prevalue) || (!prevalue && prevalue !== 0)) return value
    value = prevalue
    var currency = '£'
    var decimals = 2
    var stringified = Math.abs(value).toFixed(decimals)
    var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
    var i = _int.length % 3
    var head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? ',' : '') : ''
    var _float = decimals ? stringified.slice(-1 - decimals) : ''
    var sign = value < 0 ? '-' : ''
    return (
      sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
    )
  })

  const dataState = window.createPersistedState({
    paths: ['items']
  })

  // initial state
  // shape: [{ id, quantity }]
  const state = () => ({
    items: []
  })

  // getters
  const getters = {
    cartItems: (state, getters, rootState) =>
      state.items.map(item => {
        const { order, ...itms } = item
        itms.quantity = order.quantity
        itms.registration = order.registration
        itms.options = order.options.map(opt => opt.name)
        return itms
      }),
    cartOrders: (state, getters, rootState) =>
      state.items.map(item => item.order),
    cartItemCount: (state, getters) =>
      state.items.length
        ? state.items.reduce((total, item) => total + item.order.quantity, 0)
        : 0,
    cartTotalPrice: (state, getters) => {
      return parseFloat(
        getters.cartItems
          .reduce((total, item) => total + item.price * item.quantity, 0)
          .toFixed(2)
      )
    }
  }

  // actions
  const actions = {
    emptyCart ({ commit, state }) {
      // empty cart
      commit('setCartItems', { items: [] })
    },

    addItemToCart ({ state, commit }, item) {
      const cartItem = state.items.find(itm => itm.id === item.id)
      if (!cartItem) {
        commit('pushItemToCart', item)
      } else {
        commit('incrementItemQuantity', [cartItem.id, item.order.quantity])
      }
    },

    removeItemFromCart ({ state, commit }, itemId) {
      commit('popItemFromCart', itemId)
    },

    updateItemQuantity ({ state, commit }, args) {
      commit('setItemQuantity', args)
    }
  }

  // mutations
  const mutations = {
    pushItemToCart (state, item) {
      state.items.push(item)
    },

    popItemFromCart (state, itemId) {
      state.items = state.items.filter(item => item.id !== itemId)
    },

    incrementItemQuantity (state, [id, qty]) {
      const cartItem = state.items.find(item => item.id === id)
      cartItem.order.quantity += qty
    },

    setItemQuantity (state, [id, qty]) {
      const cartItem = state.items.find(item => item.id === id)
      cartItem.order.quantity = qty
    },

    setCartItems (state, { items }) {
      state.items = items
    }
  }

  window.store = new window.Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    plugins: [dataState]
  })
})()
